import { lazy, Suspense } from 'react';
const AppContainer = lazy(() => import('src/components/AppContainer'));
const Providers = lazy(() => import('src/providers'));
const AppRoutes = lazy(() => import('src/routes'));
const HeaderLayout = lazy(() => import('src/components/HeaderLayout'));

function App() {
  return (
    <Suspense fallback={<></>}>
      <Providers>
        <AppContainer>
          <HeaderLayout />
          <AppRoutes />
        </AppContainer>
      </Providers>
    </Suspense>
  );
}

export default App;
